/** @jsx jsx */
import { jsx, Box, Container, Flex, Styled } from "theme-ui"
import React from 'react'
import { graphql  } from "gatsby"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import {
  Hero,
  Overlay,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import {
  Layout,
  Main,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import {
  ColorBar,
  AuthorCard,
  PostCard,
} from "../components/elements"

const Author = ({ data, pageContext }) => {
  const page = data.datoCmsAuthor
  
  const {
    breadcrumb: { crumbs },
  } = pageContext

  const posts = data?.allDatoCmsPost?.edges

  const allAuthors = posts.map(edge => edge.node.author).map(author => ({ slug: author.slug, title: author.name }))
  
  const uniqueAuthors = allAuthors.reduce((acc, current) => {
      const identifier = `${current.slug}-${current.title}`;
      if (!acc.seen[identifier]) {
          acc.seen[identifier] = true;
          acc.result.push(current);
      }
      return acc;
  }, { seen: {}, result: [] }).result; 

  return (
    <Layout>
      {/* <HelmetDatoCms seo={page.seoMetaTags} /> */}

      <Main>
        <Hero>
          
            <Container
              sx={{ position: "relative", px: "1em", py: [5, 6], zIndex: 10 }}
            >
              <Styled.h1>{page?.name}</Styled.h1>
              <Breadcrumb
                crumbs={crumbs}
                crumbSeparator=" / "
                crumbLabel={page.name}
              /> 
            </Container>
            <Overlay sx={{ opacity: 1 }} />
          
          <ColorBar sx={{ position: "absolute", bottom: 0, left: 0, zIndex: 10 }} />
        </Hero>

          <Box sx={{ width:'full', bg: "warmgray", py:5}}>
            <Container sx={{ px: "1em" }}>
                <AuthorCard author={page} />
            </Container>
        </Box>
        
        {/* Content */}
        <Section id="posts">
          <Container sx={{ px: "1em" }}>
            <Styled.h2>Blogs by {page?.name}</Styled.h2>
            
            <Flex sx={{ flexWrap: "wrap", mx: [0, 0, -2], mt: 4 }}>

              {posts.map(({ node: post }) => {
                return (
                  <>
                    {post?.author?.name.includes(page?.name) &&
                      <PostCard key={post?.id} post={post} width={["full", "full", "1/3"]} />
                    }
                  </>
                )
              })}
            </Flex>
          </Container>
          
        </Section>
      </Main>
    </Layout>
  )
}

export default Author

export const query = graphql`
  query authorPageQuery($slug: String!) {
    datoCmsAuthor(
      slug: { eq: $slug }
      meta: { status: { eq: "published" } }
    ) {
      id
      meta {
        status
      }
      slug
      name
      bioNode {
        childMarkdownRemark {
          html
        }
      }
      portrait {
        fluid(maxWidth: 400, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }   
    }

    allDatoCmsPost(sort: {fields: date, order: DESC}, filter: {meta: {status: {eq: "published"}}}) {
      edges {
        node {
          author {
            slug
            name
          }
          title
          slug
          date(formatString: "MM/d/yyyy")
          id
          blogImage {
            fluid(
                  maxWidth: 240
                  imgixParams: { fm: "jpg", auto: "compress" }
                ) {
                  ...GatsbyDatoCmsFluid
            }
          }
          tags {
          ... on DatoCmsUnit {
            id
            title
            slug
          }
          ... on DatoCmsProgram {
            id
            slug
            title
          }
          ... on DatoCmsCenterOfExcellence {
            id
            slug
            title
          }
          ... on DatoCmsChildrensService {
            id
            slug
            title
          }
          ... on DatoCmsChildrensProgram {
            id
            slug
            title
          }
        }
        }
      }
    }
  }
`
